import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/international-scientific-conference/1.jpeg';
import blogd2 from '../../assets/images/2024/international-scientific-conference/2.jpeg';
import blogd3 from '../../assets/images/2024/international-scientific-conference/3.jpeg';
import blogd4 from '../../assets/images/2024/international-scientific-conference/4.jpeg';
import blogd5 from '../../assets/images/2024/international-scientific-conference/5.jpeg';
import blogd6 from '../../assets/images/2024/international-scientific-conference/6.jpeg';
import blogd7 from '../../assets/images/2024/international-scientific-conference/7.jpeg';
import blogd8 from '../../assets/images/2024/international-scientific-conference/8.jpeg';
import blogd9 from '../../assets/images/2024/international-scientific-conference/9.jpeg';
import blogd10 from '../../assets/images/2024/international-scientific-conference/10.jpeg';
import blogd11 from '../../assets/images/2024/international-scientific-conference/11.jpeg';
import blogd12 from '../../assets/images/2024/international-scientific-conference/12.jpeg';
import blogd13 from '../../assets/images/2024/international-scientific-conference/13.jpeg';
import blogd14 from '../../assets/images/2024/international-scientific-conference/14.jpeg';
import blogd15 from '../../assets/images/2024/international-scientific-conference/15.jpeg';
import blogd16 from '../../assets/images/2024/international-scientific-conference/16.jpeg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  Lagos University Teaching Hospital Hosts International
                  Scientific Conference on Interventional Cardiology
                </h2>
                <div className='blog-one__image'>
                  <img src={blogd2} alt='' />
                </div>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  The 4th Lagos University Teaching Hospital (LUTH)
                  International Scientific Conference, focusing on
                  interventional cardiology, saw leading experts convene to
                  discuss advancements and challenges in cardiovascular care.
                  <br />
                  The preconference event was held on Wednesday 29th of May,
                  2024 featured distinguished speakers, including Prof. Njideka
                  Okubadejo, Prof. Adesoji Ademuyiwa, Prof. Oyinkan Sofola, and
                  was moderated by Prof. Chris Esezobor. The section focused on
                  maintaining ethical standards in healthcare research,
                  underscoring the importance of integrity and ethical practices
                  in advancing medical science.
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd7} height={370} />
                  </div>
                  <div
                    className='col-lg-6'
                    style={{ textAlign: 'left', alignItems: 'right' }}
                  >
                    {/* <img src={blogd3} height={370}/> */}
                    <p>
                      The second day of the conference, themed "Interventional
                      Cardiology: Advancing the Frontiers in Cardiovascular
                      Care," opened with a keynote address by Dr. Adeyemi
                      Johnson. Dr. Johnson provided a comprehensive overview of
                      interventional cardiology in Nigeria, addressing the
                      economic challenges and the roles that government and
                      policymakers can play in improving cardiovascular care.
                      <br />
                      Several notable presentations followed, each contributing
                      unique insights into different aspects of cardiovascular
                      care and healthcare delivery.
                      <br />
                      Ven. Olotu Clement Oladeinde emphasized the significance
                      of Public-Private Partnerships (PPP) in healthcare. He
                      described PPP as a structured collaboration between the
                      public sector and private entities, crucial for financing
                      government projects and services. Oladeinde highlighted
                      how PPPs facilitate the sharing of managerial and
                      technical skills, reduce wastages, accelerate
                      technological development, and provide financial returns,
                      particularly benefiting the government. He cited LUTH
                      Crystal Optics, a PPP initiative under the leadership of
                      former CMD Prof. Chris Bode, as a successful example that
                      enhanced ophthalmology services and yielded significant
                      financial returns.
                    </p>
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Nurse Ndidi Enuwa Retires */}
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Dr. Olurotimi Badero discussed the critical role of Nigerian
                  health professionals in the diaspora in advancing
                  cardiovascular care. He identified key factors driving brain
                  drain, including inadequate healthcare resources, poor
                  funding, income disparities, frequent strikes, and security
                  concerns. Badero outlined the challenges to engaging the
                  diaspora, such as financial sustainability, security, and
                  bureaucratic obstacles, and stressed the need for passion and
                  commitment in overcoming these barriers.
                  <br />
                  <div className='row'>
                    <div className='col-lg-6'>
                      <img src={blogd5} height={370} />
                    </div>
                    <div className='col-lg-6'>
                      <img src={blogd6} height={370} />
                    </div>
                  </div>
                  <br />
                  Dr. Oyewole Kushimo, a consultant in cardiology, provided a
                  detailed guide on establishing a cardiac catheterization
                  laboratory. His presentation focused on the technical and
                  logistical considerations essential for setting up such a
                  facility, which is pivotal in diagnosing and treating
                  cardiovascular conditions.
                  <br />
                  The conference successfully brought together experts to share
                  knowledge, address challenges, and explore innovative
                  solutions in interventional cardiology. The discussions
                  underscored the critical role of collaboration, both within
                  the local healthcare sector and with the international
                  diaspora, in advancing cardiovascular care and improving
                  health outcomes in Nigeria.
                  <br />
                  <div className='row'>
                    <div className='col-lg-12'>
                      <img src={blogd4} height={370} />
                    </div>
                  </div>
                </p>

                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Baale of Mashewele community, community elder, HOD of
                  Community Health Dept Prof Ogunnowo, Dr Roberts ,Mushin LGA
                  Health Educator Mrs Akinterinwa and Residents in Community
                  Health.
                </span> */}
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Other pictures as found below:
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Fig 1: Residents at the department before the program */}
                  <br />
                  {/* Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach. */}
                </span>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd1} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd10} height={370} width={500} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd11} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd13} height={370} width={500} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd14} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd15} height={370} width={500} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd16} height={370} />
                  </div>
                </div>

                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Residents dispensing medications in advance for the outreach */}
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd12} height={370} width={500} />
                  </div>
                </div>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>Community Health Department</a>
                </p>
              </div>
              <div className='social-block'>
                <a href='#none'>
                  <i className='fab fa-twitter'></i>
                </a>
                <a href='#none'>
                  <i className='fab fa-facebook-f'></i>
                </a>
                <a href='#none'>
                  <i className='fab fa-instagram'></i>
                </a>
                <a href='#none'>
                  <i className='fab fa-dribbble'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='LAGOS UNIVERSITY TEACHING HOSPITAL | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
